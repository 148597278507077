import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSearchBtnList,
  searchBtnListInit,
  CLocalNav,
  CLabelCard,
  CSectTitle,
  LBanquetIncs,
  LWifi,
  AssetImage,
  CBtnList,
  CNewsList,
  LBanquetContact,
} from '../../components/_index';
import '../../assets/_sass/page/banquet.scss';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const labelData = [
    {
      tagList: ['大宴会場'],
      label: '宴会棟 3F',
      img: {
        src: '/assets/images/banquet/img_banquet.png',
        alt: '',
      },
      title: <>鳳翔</>,
      text: '人数／60～1,500名',
      link: {
        href: '/banquet/grand_hall/',
      },
    },
    {
      tagList: ['中宴会場'],
      label: '宴会棟 2F',
      img: {
        src: '/assets/images/banquet/img_banquet02.png',
        alt: '',
      },
      title: <>芙蓉</>,
      text: '人数／40～350名',

      link: {
        href: '/banquet/fuyo/',
      },
    },
    {
      tagList: ['スカイバンケットルーム'],
      label: 'ホテル棟70F',
      img: {
        src: '/assets/images/banquet/img_banquet03.png',
        alt: '',
      },
      title: <>オーロラ／レインボー</>,
      text: '人数／50～160名',
      link: {
        href: '/banquet/sky_banquet/',
      },
    },
    {
      tagList: ['小宴会場'],
      label: '宴会棟 2F',
      img: {
        src: '/assets/images/banquet/img_banquet04.png',
        alt: '',
      },
      title: <>カメリア</>,
      text: '人数／20～70名',
      link: {
        href: '/banquet/camellia/',
      },
    },
    {
      tagList: ['小宴会場'],
      label: '宴会棟 2F',
      img: {
        src: '/assets/images/banquet/img_banquet05.png',
        alt: '',
      },
      title: <>ダリア</>,
      text: '人数／10～50名',
      link: {
        href: '/banquet/dahlia/',
      },
    },
    {
      tagList: ['小宴会場'],
      label: '宴会棟 2F',
      img: {
        src: '/assets/images/banquet/img_banquet06.png',
        alt: '',
      },
      title: <>アゼリア</>,
      text: '人数／10～50名',
      link: {
        href: '/banquet/azalea/',
      },
    },
    {
      tagList: ['小宴会場'],
      label: '宴会棟 2F',
      img: {
        src: '/assets/images/banquet/img_banquet07.png',
        alt: '',
      },
      title: <>プルミエール</>,
      text: '人数／4～14名',
      link: {
        href: '/banquet/premiere/',
      },
    },
    {
      tagList: ['小宴会場'],
      label: '宴会棟 4F',
      img: {
        src: '/assets/images/banquet/img_banquet08.png',
        alt: '',
      },
      title: <>さくら／けやき／いちょう／すずかけ</>,
      text: '人数／2～40名',
      link: {
        href: '/banquet/4th_floor/',
      },
    },
  ];

  const banquetCategories = [
    'すべて',
    '大宴会場',
    '中宴会場',
    'スカイバンケットルーム',
    '小宴会場',
  ];
  const banquetSearchBtnData = banquetCategories.map((item) => {
    return {
      value: item,
    };
  });
  const banquetSearchInit = searchBtnListInit<string>(
    banquetSearchBtnData[0].value
  );
  const banquetFilterData = labelData.filter((data) => {
    if (banquetSearchInit.check === 'すべて') {
      return true;
    } else {
      return data.tagList.includes(banquetSearchInit.check);
    }
  });

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'BANQUET',
            sub: '宴会・会議',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '宴会・会議',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect02 u_mb30_pc">
        <LWrap>
          <p className="c_sectLead">
            横浜市内最大級の「大宴会場」をはじめ、日本で一番高い場所にある「スカイバンケットルーム」など、
            <br />
            さまざまなご要望にお応えできる大小12の宴会場がございます。
          </p>
          <CSectTitle
            id="banquetList"
            title={{ ja: '会場で選ぶ', en: 'BANQUET' }}
          />
          <CSearchBtnList<string>
            name="banquet"
            onChange={banquetSearchInit.radio}
            data={banquetSearchBtnData}
            check={banquetSearchInit.check}
          />
          <CLabelCard data={banquetFilterData} />
        </LWrap>
      </section>
      <LBanquetIncs exClass="item_center" />
      <section className="u_mbLarge">
        <LWrap>
          <CSectTitle
            exClass="u_mtLarge"
            title={{ ja: 'お料理', en: 'CUISINE' }}
          />
          <p className="c_sectLead">
            世界のVIPをお迎えしてきたサービスとおもてなしがここにはあります。
            <br />
            世界の舞台になったこの場所だからこその、お料理のおもてなしが私たちのホテルブランドです
          </p>
          <section className="p_banquetInfinityMediaUnit">
            <div className="p_banquetInfinityMedia">
              <div className="p_banquetImgWrapper">
                <div className="imgBody">
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet09.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet10.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet11.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet12.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet13.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet14.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet15.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                </div>
                <div className="imgBody">
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet09.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet10.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet11.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet12.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet13.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet14.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                  <figure>
                    <AssetImage
                      src="/assets/images/banquet/img_banquet15.png"
                      alt=""
                      loading="lazy"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <CBtnList
              data={[
                {
                  label: 'お料理',
                  link: {
                    href: '/banquet/cuisine/',
                  },
                },
              ]}
            />
          </section>
        </LWrap>
      </section>
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'お知らせ', en: 'INFORMATION' }} />
          <CNewsList
            data={infoChoice(infoGet(), ['宴会・会議'], 5)}
            label={false}
          />
          <CBtnList
            data={[
              {
                label: 'お知らせ',
                link: {
                  href: '/information/',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
